import React, { useState, useEffect, useRef, useCallback } from "react";
import { Upload, Folder, File, X, ChevronRight, ChevronDown } from 'lucide-react';
import moment from 'moment-timezone';
import './UploadMaster.css'
// import { Upload } from "@aws-sdk/lib-storage";
import { S3Client, S3 } from "@aws-sdk/client-s3";
// import { createWorker } from './workerSetup';
// Assets
import HintIcon from './../Assets/hint-icon.png'
import Swal from 'sweetalert2'
import userIcon from '../Assets/person.png'
import emailIcon from '../Assets/email.png'
import passwordIcon from '../Assets/password.png'
import doctorIcon from '../Assets/doctor.png'
import genderIcon from '../Assets/gender.png'
import professionIcon from '../Assets/profession.png'
import confirmCodeIcon from '../Assets/confirmCode.png'
import countryIcon from '../Assets/countries.png'
import hospitalIcon from '../Assets/hospital.png'
import descriptionIcon from '../Assets/description.bmp'
import { COUNTRY_OPTIONS } from '../countriesData.js'
import surgeonslab_logo from '../Assets/surgeonslab_logo.png'
import { generateClient } from "aws-amplify/api"
import { signUp, confirmSignUp } from "aws-amplify/auth";
import { createUserInfoSurgView } from "../../../src/graphql/mutations";
import { useNavigate, useLocation } from 'react-router-dom';
import { type } from "@testing-library/user-event/dist/type/index.js";
import { gsap } from 'gsap';
import FileUpload from './FileUpload';

const AWS = require('aws-sdk');
const UploadMaster = () => {

  const API = generateClient();
  const navigate = useNavigate();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [dataDesc, setDataDesc] = useState('');
  const [email, setEmail] = useState('');
  const [hospital, setHospital] = useState('');
  const [policyCheck, setPolicyCheck] = useState('');
  const [progressBarVisible, setProgressBarVisible] = useState(false);

  
  const location = useLocation();
  const { state } = location;
  const { name, emailid } = state || {};
  // console.log(username);
  // console.log(email);


//Start of FILE UPLOAD

const [fileStructure, setFileStructure] = useState(new Map());
const [expandedFolders, setExpandedFolders] = useState(new Set());
const [isDragging, setIsDragging] = useState(false);
const [uploadStatus, setUploadStatus] = useState('');
const [totalProgress, setTotalProgress] = useState(0);
const [isUploading, setIsUploading] = useState(false);
// const folderInputRef = useRef(null);


const [isProcessing, setIsProcessing] = useState(false);
const [processingProgress, setProcessingProgress] = useState({ processed: 0, total: 0 });
const workerRef = useRef();
const folderInputRef = useRef();
const [worker, setWorker] = useState(null);
const [fileProgress, setFileProgress] = useState(new Map());


useEffect(() => {
  const handleBeforeUnload = (e) => {
    if (isUploading) {
      e.preventDefault();
      e.returnValue = 'Changes you made may not be saved.';
      return e.returnValue;
    }
  };
  const worker = new Worker(new URL('./fileWorker.js', import.meta.url));
  workerRef.current = worker;

  worker.onmessage = (e) => {
    const { type, payload } = e.data;
    
    switch (type) {
      case 'PROCESSING_PROGRESS':
        setProcessingProgress(prev => ({
          processed: prev.processed + payload.size,
          total: prev.total
        }));
        break;
        
      case 'PROCESS_COMPLETE':
        // Merge new files with existing files
        setFileStructure(prevStructure => {
          const newStructure = new Map(payload.fileStructure);
          const mergedStructure = new Map(prevStructure);
          
          // Merge new files into existing structure
          newStructure.forEach((value, key) => {
            if (!mergedStructure.has(key)) {
              mergedStructure.set(key, value);
            } else {
              // If path exists, append a number to make it unique
              let counter = 1;
              let newKey = key;
              while (mergedStructure.has(newKey)) {
                const lastDotIndex = key.lastIndexOf('.');
                if (lastDotIndex === -1) {
                  newKey = `${key}_${counter}`;
                } else {
                  newKey = `${key.substring(0, lastDotIndex)}_${counter}${key.substring(lastDotIndex)}`;
                }
                counter++;
              }
              mergedStructure.set(newKey, value);
            }
          });
          
          return mergedStructure;
        });
        setIsProcessing(false);
        setProcessingProgress({ processed: 0, total: 0 });
        break;
        
      case 'PROCESS_ERROR':
        console.error('File processing error:', payload.error);
        setIsProcessing(false);
        alert("Failed to process files. Please try again.");
        break;
    }
  };

  window.addEventListener('beforeunload', handleBeforeUnload);

  return () => {
    window.removeEventListener('beforeunload', handleBeforeUnload);
    if (workerRef.current) {
      workerRef.current.terminate();
    }
  };
}, [isUploading]);

const processFiles = useCallback((entries) => {
  if (!worker) return;
  
  // Send files to worker for processing
  worker.postMessage({
    type: 'PROCESS_FILES',
    data: entries
  });
}, [worker]);

const calculateTotalSize = (entries) => {
  if (!entries || !Array.isArray(entries)) return 0;
  return entries.reduce((total, entry) => {
    const size = entry?.size || entry?.file?.size || 0;
    return total + size;
  }, 0);
};

// const onDrop = useCallback((e) => {
//   e.preventDefault();
//   setIsDragging(false);
//   setIsProcessing(true);

//   const items = Array.from(e.dataTransfer.items);
//   const entries = [];

//   const processEntry = async (entry) => {
//     return new Promise((resolve) => {
//       if (entry.isFile) {
//         entry.file(file => {
//           entries.push({
//             type: 'file',
//             name: file.name,
//             file: file,
//             fullPath: entry.fullPath,
//             size: file.size
//           });
//           resolve();
//         });
//       } else if (entry.isDirectory) {
//         const dirReader = entry.createReader();
//         const readEntries = () => {
//           dirReader.readEntries(async (subEntries) => {
//             if (subEntries.length > 0) {
//               await Promise.all(subEntries.map(processEntry));
//               readEntries();
//             } else {
//               resolve();
//             }
//           });
//         };
//         readEntries();
//       }
//     });
//   };

//   Promise.all(items.map(item => {
//     const entry = item.webkitGetAsEntry();
//     return entry ? processEntry(entry) : Promise.resolve();
//   })).then(() => {
//     if (entries.length > 0) {
//       const totalSize = calculateTotalSize(entries);
//       setProcessingProgress({ processed: 0, total: totalSize });
      
//       // Send entries to worker for processing
//       workerRef.current?.postMessage({
//         type: 'PROCESS_FILES',
//         data: entries
//       });
//     } else {
//       setIsProcessing(false);
//     }
//   });
// }, []);

const onDrop = useCallback(async (e) => {
  e.preventDefault();
  setIsDragging(false);
  setIsProcessing(true);

  const items = Array.from(e.dataTransfer.items);
  const tempFileStructure = new Map(fileStructure); // Clone existing structure
  const entries = [];

  // Helper function to generate unique path
  const getUniquePath = (basePath, name, isDirectory = false) => {
    let path = `${basePath}/${name}`.replace(/\/+/g, '/');
    let counter = 1;
    let uniquePath = path;
    const extension = isDirectory ? '' : name.includes('.') ? name.substring(name.lastIndexOf('.')) : '';
    const baseName = isDirectory ? name : name.includes('.') ? name.substring(0, name.lastIndexOf('.')) : name;

    while (tempFileStructure.has(uniquePath)) {
      uniquePath = `${basePath}/${baseName} (${counter})${extension}`.replace(/\/+/g, '/');
      counter++;
    }
    return uniquePath;
  };

  // Helper function to process file and folder entries
  const processEntry = async (entry, parentPath = '') => {
    return new Promise((resolve, reject) => {
      if (entry.isFile) {
        entry.file((file) => {
          const uniquePath = getUniquePath(parentPath, file.name);
          const fileName = uniquePath.split('/').pop();

          entries.push({
            type: 'file',
            name: fileName,
            file: file,
            fullPath: uniquePath,
            size: file.size
          });

          // Add file to the temporary structure
          tempFileStructure.set(uniquePath, {
            type: 'file',
            name: fileName,
            path: uniquePath,
            size: file.size
          });

          resolve();
        }, (error) => reject(error));
      } else if (entry.isDirectory) {
        const dirReader = entry.createReader();
        const uniqueDirPath = getUniquePath(parentPath, entry.name, true);
        const dirName = uniqueDirPath.split('/').pop();

        // Add directory to the temporary structure
        tempFileStructure.set(uniqueDirPath, {
          type: 'directory',
          name: dirName,
          path: uniqueDirPath
        });

        const readEntries = () => {
          dirReader.readEntries(async (subEntries) => {
            if (subEntries.length > 0) {
              try {
                await Promise.all(subEntries.map((subEntry) => processEntry(subEntry, uniqueDirPath)));
                readEntries();
              } catch (error) {
                reject(error);
              }
            } else {
              resolve();
            }
          });
        };
        readEntries();
      } else {
        resolve(); // Entry is neither a file nor a directory
      }
    });
  };

  try {
    // Process all items concurrently
    await Promise.all(items.map(async (item) => {
      const entry = item.webkitGetAsEntry();
      if (entry) {
        await processEntry(entry);
      }
    }));

    // Update file structure state with merged structure
    setFileStructure(tempFileStructure);

    // Send only new entries to the web worker for processing
    if (entries.length > 0) {
      const totalSize = calculateTotalSize(entries);
      setProcessingProgress({ processed: 0, total: totalSize });

      workerRef.current?.postMessage({
        type: 'PROCESS_FILES',
        data: entries
      });
    } else {
      setIsProcessing(false);
    }
  } catch (error) {
    console.error('Error processing dropped items:', error);
    Swal.fire({
      title: "Error!",
      text: "Failed to process some dropped items. Please try again.",
      icon: "error"
    });
    setIsProcessing(false);
  }
}, [fileStructure]);

// const handleFolderSelect = useCallback(async (e) => {
//   setIsProcessing(true);
//   const tempFileStructure = new Map(fileStructure); // Clone existing structure
//   const entries = [];

//   const files = Array.from(e.target.files);

//   // Skip if no files selected
//   if (files.length === 0) {
//     setIsProcessing(false);
//     return;
//   }

//   // Get the root folder structure from the first file's path
//   const firstFile = files[0];
//   const rootPath = firstFile.webkitRelativePath.split('/')[0];

//   // Check if the root folder already exists
//   if (!tempFileStructure.has(rootPath)) {
//     // Create the root folder in the structure
//     tempFileStructure.set(rootPath, {
//       type: 'directory',
//       name: rootPath,
//       path: rootPath
//     });
//   }

//   // Process all files and ensure they are unique
//   for (const file of files) {
//     const filePath = file.webkitRelativePath;
//     const fileName = file.name;

//     // Check if the file already exists in the structure
//     if (!tempFileStructure.has(filePath)) {
//       // Add the file entry
//       entries.push({
//         type: 'file',
//         name: fileName,
//         file: file,
//         fullPath: filePath,
//         size: file.size
//       });

//       // Add the file to the temporary structure
//       tempFileStructure.set(filePath, {
//         type: 'file',
//         name: fileName,
//         path: filePath,
//         size: file.size
//       });
//     }
//   }

//   try {
//     // Update the file structure state with the new structure
//     setFileStructure(tempFileStructure);

//     // Send the new entries to the web worker for processing
//     if (entries.length > 0) {
//       const totalSize = calculateTotalSize(entries);
//       setProcessingProgress({ processed: 0, total: totalSize });

//       // Offload processing to the web worker
//       workerRef.current?.postMessage({
//         type: 'PROCESS_FILES',
//         data: entries
//       });
//     }
//   } catch (error) {
//     console.error('Error processing selected folder:', error);
//     Swal.fire({
//       title: "Error!",
//       text: "Failed to process selected folder. Please try again.",
//       icon: "error"
//     });
//   } finally {
//     setIsProcessing(false);
//     if (folderInputRef.current) {
//       folderInputRef.current.value = null;
//     }
//   }
// }, [fileStructure]);

const handleFolderSelect = useCallback(async (e) => {
  setIsProcessing(true);
  const tempFileStructure = new Map(fileStructure); // Clone existing structure
  const entries = [];

  const files = Array.from(e.target.files);

  // Skip if no files selected
  if (files.length === 0) {
    setIsProcessing(false);
    return;
  }

  // Helper function to generate unique path
  const getUniquePath = (basePath, name, isDirectory = false) => {
    let path = `${basePath}/${name}`.replace(/\/+/g, '/');
    let counter = 1;
    let uniquePath = path;
    const extension = isDirectory ? '' : name.includes('.') ? name.substring(name.lastIndexOf('.')) : '';
    const baseName = isDirectory ? name : name.includes('.') ? name.substring(0, name.lastIndexOf('.')) : name;

    while (tempFileStructure.has(uniquePath)) {
      uniquePath = `${basePath}/${baseName} (${counter})${extension}`.replace(/\/+/g, '/');
      counter++;
    }
    return uniquePath;
  };

  // Get the root folder structure from the first file's path
  const firstFile = files[0];
  const originalRootPath = firstFile.webkitRelativePath.split('/')[0];
  
  // Generate unique root path
  const rootPath = getUniquePath('', originalRootPath, true);
  // console.log(rootPath);
  // Create the root folder in the structure if it doesn't exist
  tempFileStructure.set(rootPath, {
    type: 'directory',
    name: rootPath,
    path: rootPath
  });

  // Process all files and create directory structure
  for (const file of files) {
    const pathParts = file.webkitRelativePath.split('/');
    
    // Replace the original root folder name with our unique root folder name
    pathParts[0] = rootPath;
    
    // Create all necessary parent directories
    let currentPath = '';
    for (let i = 0; i < pathParts.length - 1; i++) {
      const part = pathParts[i];
      const newPath = currentPath ? `${currentPath}/${part}` : part;
      
      if (!tempFileStructure.has(newPath)) {
        tempFileStructure.set(newPath, {
          type: 'directory',
          name: part,
          path: newPath
        });
      }
      currentPath = newPath;
    }

    // Generate unique path for the file
    const fileName = pathParts[pathParts.length - 1];
    const uniqueFilePath = getUniquePath(currentPath, fileName);

    // Add the file entry
    entries.push({
      type: 'file',
      name: fileName,
      file: file,
      fullPath: uniqueFilePath,
      size: file.size
    });

    // Add the file to the temporary structure
    tempFileStructure.set(uniqueFilePath, {
      type: 'file',
      name: fileName,
      path: uniqueFilePath,
      size: file.size
    });
  }

  try {
    // Update the file structure state with the new structure
    setFileStructure(tempFileStructure);

    // Send the new entries to the web worker for processing
    if (entries.length > 0) {
      const totalSize = calculateTotalSize(entries);
      setProcessingProgress({ processed: 0, total: totalSize });

      workerRef.current?.postMessage({
        type: 'PROCESS_FILES',
        data: entries
      });
    }
  } catch (error) {
    console.error('Error processing selected folder:', error);
    Swal.fire({
      title: "Error!",
      text: "Failed to process selected folder. Please try again.",
      icon: "error"
    });
  } finally {
    if (folderInputRef.current) {
      folderInputRef.current.value = null;
    }
    // Only set processing to false after the worker has finished
    if (entries.length === 0) {
      setIsProcessing(false);
    }
  }
}, [fileStructure]);

const handleFileSelect = useCallback((e) => {
  // Collect file entries from the input
  const entries = Array.from(e.target.files).map(file => ({
    type: 'file',  // Ensure consistency with the drag-and-drop implementation
    name: file.name,
    file: file,  // Pass the file directly
    fullPath: file.name,
    size: file.size
  }));

  // Calculate total size for progress tracking
  const totalSize = calculateTotalSize(entries);
  setProcessingProgress({ processed: 0, total: totalSize });
  setIsProcessing(true);

  // Send entries to the worker for processing
  workerRef.current?.postMessage({
    type: 'PROCESS_FILES',
    data: entries
  });

  // Reset the file input field
  if (e.target) {
    e.target.value = null;
  }
}, []);

// Render loading indicator during processing
const renderProcessingStatus = () => {
  if (!isProcessing)
  { 
    return null;
  }
  const processedMB = (processingProgress.processed / (1024 * 1024)).toFixed(2);
  const totalMB = (processingProgress.total / (1024 * 1024)).toFixed(2);
  const percentage = totalMB > 0 
    ? Math.round((processingProgress.processed / processingProgress.total) * 100)
    : 0;

  return (
    <div className="mt-4 p-4 border rounded-lg bg-white">
      <div className="w-full bg-gray-200 rounded-full h-2.5">
        <div 
          className="bg-blue-600 h-2.5 rounded-full transition-all duration-300" 
          style={{ width: `${percentage}%` }}
        />
      </div>
      <div className="text-sm text-gray-600 mt-2">
        Processing: {processedMB}MB of {totalMB}MB ({percentage}%)
        <br></br><br></br>
        <i>After processing, click 'Submit' to upload files.</i>
      </div>
    </div>
  );
};

const processFileEntry = (entry, path = '') => {
return new Promise((resolve, reject) => {
  if (entry.isFile) {
    entry.file(file => {
      const fullPath = path ? `${path}/${file.name}` : file.name;
      setFileStructure(prev => {
        const newStructure = new Map(prev);
        newStructure.set(fullPath, {
          file,
          type: 'file',
          name: file.name,
          path: fullPath,
          size: file.size // Adding size for progress tracking
        });
        return newStructure;
      });
      resolve();
    }, reject);
  } else if (entry.isDirectory) {
    const dirPath = path ? `${path}/${entry.name}` : entry.name;
    setFileStructure(prev => {
      const newStructure = new Map(prev);
      newStructure.set(dirPath, {
        type: 'directory',
        name: entry.name,
        path: dirPath
      });
      return newStructure;
    });
    
    const dirReader = entry.createReader();
    const readEntries = () => {
      dirReader.readEntries(async (entries) => {
        if (entries.length > 0) {
          try {
            await Promise.all(entries.map(e => processFileEntry(e, dirPath)));
            readEntries(); // Continue reading if there might be more entries
          } catch (error) {
            reject(error);
          }
        } else {
          resolve();
        }
      }, reject);
    };
    readEntries();
  }
});
};

const directoryExists = (path, structure) => {
return Array.from(structure.values()).some(
  item => item.type === 'directory' && item.path === path
);
};

const onDragOver = useCallback((e) => {
e.preventDefault();
setIsDragging(true);
}, []);

const onDragLeave = useCallback((e) => {
e.preventDefault();
setIsDragging(false);
}, []);

const toggleFolder = (path) => {
  setExpandedFolders(prev => {
    const newSet = new Set(prev);
    if (newSet.has(path)) {
      newSet.delete(path);
    } else {
      newSet.add(path);
    }
    return newSet;
  });
};

const removeItem = (path) => {
  setFileStructure(prev => {
    const newStructure = new Map(prev);
    // Remove the item and all its children
    for (const key of newStructure.keys()) {
      if (key === path || key.startsWith(`${path}/`)) {
        newStructure.delete(key);
      }
    }
    return newStructure;
  });
};

const getCurrentDateTimeWithTimezone = () => {
const now = moment();
const dateTime = now.toISOString(); // Get ISO format
const timezone = now.tz(moment.tz.guess()).format('z'); // Get timezone abbreviation

let result = dateTime + timezone;
result = result.replace(/:/g, '.');

return result;
};

const s3 = new AWS.S3({
  region: 'eu-central-1',  // Replace with your S3 bucket's region
  accessKeyId: 'AKIA5KUEDNW2TFBW7IO5',  // Replace with your AWS access key ID
  secretAccessKey: '23WebUuULTitju2vhDkc8H95a4ZlR2laZsoJS/dw'  // Replace with your AWS secret access key
});

// Retry helper function (as before)
const uploadFileWithRetry = (params, retries, onProgress) => {
  return new Promise((resolve, reject) => {
    const upload = s3.upload(params);
    
    upload.on('httpUploadProgress', (progress) => {
      if (onProgress) {
        onProgress(progress.loaded, progress.total);
      }
    });

    upload.send((err, data) => {
      if (err) {
        if (retries > 0) {
          console.warn(`Retrying upload for ${params.Key}. Attempts left: ${retries - 1}`);
          setTimeout(() => {
            uploadFileWithRetry(params, retries - 1, onProgress).then(resolve).catch(reject);
          }, 1000);
        } else {
          reject(err);
        }
      } else {
        resolve(data);
      }
    });
  });
};

const uploadToS3 = useCallback(async () => {
  // Debug logs
  console.log('Starting upload process');
  // console.log('File structure:', fileStructure);

  // Input validation
  if (!username || !email || !dataDesc || !hospital) {
    Swal.fire({
      title: "Error!",
      text: "Kindly fill all the details",
      icon: "warning"
    });
    return;
  }

  // Check if fileStructure exists and has items
  if (!fileStructure || fileStructure.size === 0) {
    Swal.fire({
      title: "Error!",
      text: "No file/folder is selected for upload.",
      icon: "warning"
    });
    return;
  }

  if (!policyCheck) {
    Swal.fire({
      title: "Error!",
      text: "Kindly agree to the Terms of Use before uploading",
      icon: "warning"
    });
    return;
  }

  try {
    setIsUploading(true);
    setTotalProgress(0);
    setUploadStatus('Preparing files for upload...');

    // Get all files to upload while preserving directory structure
    const fileEntries = Array.from(fileStructure.entries())
      .filter(([_, item]) => {
        // console.log('Checking item:', item);
        return item && item.type === 'file' && item.file;
      })
      .map(([path, item]) => {
        // console.log('Processing file for upload:', path);
        return [path, item];
      });
    
    // console.log('Files to upload:', fileEntries);

    const totalSize = fileEntries.reduce((sum, [_, item]) => {
      const fileSize = item.file.size || 0;
      // console.log(`File size for ${item.name}:`, fileSize);
      return sum + fileSize;
    }, 0);
    
    console.log('Total size to upload:', totalSize);
    
    if (fileEntries.length === 0) {
      throw new Error('No valid files found for upload');
    }

    let uploadedBytes = 0;
    const maxConcurrentUploads = 5;
    const errors = [];
    const currentDateTime = getCurrentDateTimeWithTimezone();

    // Create S3 upload function with progress
    const uploadFileToS3 = async (path, item) => {
      // Preserve directory structure in S3 key
      const sanitizedPath = path.replace(/^\/+/, '').replace(/[^\w\-./]/g, '_');
      
      
      const params = {
        Bucket: 'pre-op-database',
        Key: `Patient-data/${email}/${currentDateTime}/${sanitizedPath}`,
        Body: item.file,
        ContentType: item.file.type || 'application/octet-stream'
      };

      try {
        // console.log(`Starting upload for: ${path} (${item.file.size} bytes)`);
        
        const upload = s3.upload(params);
        
        // Track progress for this file
        upload.on('httpUploadProgress', (progress) => {
          const increment = progress.loaded - (item.file._lastLoaded || 0);
          item.file._lastLoaded = progress.loaded;
          
          uploadedBytes += increment;
          const percentage = (uploadedBytes / totalSize) * 100;
          
          // console.log(`Upload progress for ${path}: ${percentage.toFixed(2)}%`);
          setTotalProgress(Math.min(percentage, 100));
          
          const uploadedMB = (uploadedBytes / (1024 * 1024)).toFixed(2);
          const totalMB = (totalSize / (1024 * 1024)).toFixed(2);
          setUploadStatus(`Uploading: ${uploadedMB}MB of ${totalMB}MB (${Math.round(percentage)}%)`);
        });

        await upload.promise();
        // console.log(`Successfully uploaded: ${path}`);
        return true;
      } catch (error) {
        console.error(`Error uploading ${path}:`, error);
        errors.push({ path, error });
        return false;
      }
    };

    // Process files in chunks
    const chunks = [];
    for (let i = 0; i < fileEntries.length; i += maxConcurrentUploads) {
      chunks.push(fileEntries.slice(i, i + maxConcurrentUploads));
    }

    console.log(`Processing ${chunks.length} chunks`);

    for (const chunk of chunks) {
      const uploadPromises = chunk.map(([path, item]) => uploadFileToS3(path, item));
      await Promise.all(uploadPromises);
    }

    console.log('Upload process completed');
    console.log('Errors:', errors);

    // Handle completion
    if (errors.length === 0) {
      setUploadStatus('Upload successful!');
      invokeLambdaFunction(username, email, hospital, dataDesc, currentDateTime);
      Swal.fire({
        title: "Success!",
        text: "Data uploaded successfully, you will receive an acknowledgement mail once the files are ready.",
        icon: "success",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          window.scrollTo(0, 0);
          window.location.reload();
        }
      });
    } else {
      const failedCount = errors.length;
      const successCount = fileEntries.length - failedCount;
      
      Swal.fire({
        title: "Partial Upload",
        text: `${successCount} files uploaded successfully, ${failedCount} files failed. Please try again with failed files.`,
        icon: "warning"
      });
    }
  } catch (error) {
    console.error('Upload process error:', error);
    Swal.fire({
      title: "Error!",
      text: error.message || "Upload failed. Please try again.",
      icon: "error"
    });
  } finally {
    setIsUploading(false);
  }
}, [fileStructure, username, email, dataDesc, hospital, policyCheck]);

// const renderFileTree = () => {
//   console.log(fileStructure)
//   // Collect all root-level items (items without a '/' in their name or the top-level folders)
//   const rootItems = Array.from(fileStructure.entries()).filter(([itemPath, item]) => {
//     // Root-level items do not have '/' in their name, or their parent path is the root
//     return itemPath.split('/').length === 2; // Assuming root folder names have this structure
//   });

//   return rootItems.map(([itemPath, item]) => {
//     const isExpanded = expandedFolders.has(itemPath);

//     return (
//       <div key={itemPath} className="ml-4">
//         <div className="flex items-center py-1">
//           {item.type === 'directory' && (
//             <button
//               onClick={() => toggleFolder(itemPath)}
//               className="p-1 hover:bg-gray-100 rounded"
//             >
//               {isExpanded ? (
//                 <ChevronDown className="w-4 h-4" />
//               ) : (
//                 <ChevronRight className="w-4 h-4" />
//               )}
//             </button>
//           )}
//           {item.type === 'directory' ? (
//             <Folder className="w-4 h-4 mx-2 text-blue-500" />
//           ) : (
//             <File className="w-4 h-4 mx-2 text-gray-500" />
//           )}
//           <span className="text-sm flex-1">{item.name}</span>
//           <button
//             onClick={!isUploading ? () => removeItem(itemPath) : null}
//             className={`p-1 ${isUploading ? 'text-gray-400 cursor-not-allowed' : 'hover:text-red-600'}`}
//             disabled={isUploading}
//           >
//             <X className="w-4 h-4" />
//           </button>
//         </div>
//         {item.type === 'directory' && isExpanded && (
//           <div className="ml-4">
//             {/* Render children items if needed */}
//           </div>
//         )}
//       </div>
//     );
//   });
// };
//END of FILE UPLOAD

const renderFileTree = (path = '', level = 0) => {
  // Normalize the file structure to prevent duplicates and standardize paths
  const normalizedStructure = new Map();
  fileStructure.forEach((item, itemPath) => {
    // Remove leading slash and normalize the path
    const normalizedPath = itemPath.replace(/^\/+/, '');
    
    // Keep only the entry with the file object if duplicate exists
    if (!normalizedStructure.has(normalizedPath) || item.file) {
      normalizedStructure.set(normalizedPath, {
        ...item,
        path: normalizedPath,
        name: item.name || normalizedPath.split('/').pop()
      });
    }
  });

  // Helper function to get directory contents
  const getDirectoryContents = (dirPath) => {
    const contents = new Set();
    normalizedStructure.forEach((_, itemPath) => {
      if (itemPath.startsWith(dirPath ? `${dirPath}/` : '')) {
        const remainingPath = itemPath.slice(dirPath ? dirPath.length + 1 : 0);
        const nextPart = remainingPath.split('/')[0];
        if (nextPart) {
          contents.add(nextPart);
        }
      }
    });
    return Array.from(contents);
  };

  // Get items at current level
  const currentLevelItems = getDirectoryContents(path).map(itemName => {
    const fullPath = path ? `${path}/${itemName}` : itemName;
    const item = normalizedStructure.get(fullPath) || {
      type: 'directory',
      name: itemName,
      path: fullPath
    };
    return [fullPath, item];
  });

  return currentLevelItems.map(([itemPath, item]) => {
    const isExpanded = expandedFolders.has(itemPath);
    const hasChildren = Array.from(normalizedStructure.keys()).some(path => 
      path.startsWith(`${itemPath}/`)
    );

    // Format file size if available
    const formatFileSize = (bytes) => {
      if (!bytes) return '';
      const units = ['B', 'KB', 'MB', 'GB'];
      let size = bytes;
      let unitIndex = 0;
      while (size >= 1024 && unitIndex < units.length - 1) {
        size /= 1024;
        unitIndex++;
      }
      return ` (${size.toFixed(1)} ${units[unitIndex]})`;
    };

    return (
      <div key={itemPath} className="ml-4">

        <div className={`flex items-center ${
              isUploading || isProcessing
                ? 'text-gray-400 cursor-not-allowed' 
                : 'hover:bg-gray-50 rounded'
            }`}>
          {/* Expand/Collapse button for directories */}
          {item.type === 'directory' && hasChildren && (
            <button
              onClick={() => toggleFolder(itemPath)}
              className="p-1 hover:bg-gray-100 rounded"
              disabled={isUploading || isProcessing}
            >
              {isExpanded ? (
                <ChevronDown className="w-4 h-4" />
              ) : (
                <ChevronRight className="w-4 h-4" />
              )}
            </button>
          )}
          
          {/* Indent for items without expand/collapse button */}
          {(!hasChildren || item.type !== 'directory') && (
            <span className="w-6"></span>
          )}
          
          {/* Icon */}
          {item.type === 'directory' ? (
            <Folder className="w-4 h-4 mx-2 text-blue-500" />
          ) : (
            <File className="w-4 h-4 mx-2 text-gray-500" />
          )}
          
          {/* File/Directory name and size */}
          <span className="text-sm flex-1">
            {item.name}
            {item.type === 'file' && (
              <span className="text-gray-500 text-xs">
                {formatFileSize(item.size)}
              </span>
            )}
          </span>
          
          {/* Remove button */}
          <button
            onClick={!isUploading ? () => removeItem(itemPath) : null}
            className={`p-1 ${
              isUploading || isProcessing
                ? 'text-gray-400 cursor-not-allowed' 
                : 'hover:text-red-600 hover:bg-gray-100 rounded'
            }`}
            disabled={isUploading || isProcessing}
            title={isUploading || isProcessing ? 'Cannot remove while uploading' : 'Remove'}
          >
            <X className="w-4 h-4" />
          </button>
        </div>
        
        {/* Render children if directory is expanded */}
        {item.type === 'directory' && isExpanded && (
          <div className="ml-4">
            {renderFileTree(itemPath, level + 1)}
          </div>
        )}
      </div>
    );
  });
};

  const handleCheckboxChange = () => {
      setPolicyCheck(!policyCheck); // Toggle the state of isChecked
    };

  const [action, setAction] = useState("Patient Data Upload");
  const submitStyle = {
      background: '#033030',
    };
  const textStyle = {
      color: '#033030',
    };

    // Function to invoke the Lambda function
  const invokeLambdaFunction = (username, email, hospitalname, description, datetime) => {
      // Set the region of your Lambda function
      AWS.config.update({
      region: 'eu-central-1',
      accessKeyId: 'AKIA5KUEDNW2TFBW7IO5',
      secretAccessKey: '23WebUuULTitju2vhDkc8H95a4ZlR2laZsoJS/dw'
      });
  
      // Create a new Lambda service object
      const lambda = new AWS.Lambda();
  
      // Parameters for invoking the Lambda function
      const params = {
      FunctionName: 'dicom-upload-acknowlegment',
      InvocationType: 'RequestResponse', // Synchronous invocation
      LogType: 'Tail',
      Payload: JSON.stringify({
          'username': username,
          'email': email,
          'hospitalname': hospitalname,
          'description': description,
          'datetime': datetime
        }) // Input payload required by your Lambda function
      };
  
      // Invoke the Lambda function
      lambda.invoke(params, function(err, data) {
      if (err) {
          console.log(err, err.stack);
      } else {
          // console.log(data);
      }
      });
  };
    
  return (
      <div className="NavBar">
          <nav>
              <div className="logo">
                  <img className="logoPic" src={surgeonslab_logo} alt="SurgeonsLab Logo" />
              </div>
          </nav>

          <div className='container'>
              <div className="header">
                  <div className="text" style={textStyle}>{action}</div>
                  <div className="underline" style={submitStyle}></div>
              </div>
              {/* <form onSubmit={handleSubmit}> */}
              <div className="inputs">
                  <div className="input">
                      <img src={userIcon} alt="User" />
                      <input type="text" placeholder="Name" id="username" onChange={(e) => setUsername(e.target.value)}  disabled={isUploading}/>
                  </div> 
                  <div className="input">
                      <img src={emailIcon} alt="Email" />
                      <input type="email" placeholder="Email" id="emailid" onChange={(e) => setEmail(e.target.value)} disabled={isUploading}/>
                  </div>
                  <div className="input">
                      <img src={hospitalIcon} alt="Hospital/Institution" />
                      <input type="text" placeholder="Hospital/Institution" id="hospital" onChange={(e) => setHospital(e.target.value)} disabled={isUploading}/>
                  </div>
                  <div className="input">
                      <img src={descriptionIcon} alt="dataType" />
                      <input type="text" placeholder="Dataset Description" id="dataDesc" onChange={(e) => setDataDesc(e.target.value)} disabled={isUploading}/>
                  </div>

<div className="w-full max-w-3xl mx-auto p-4">
<div
    className={`upload-area ${isDragging ? 'dragging' : ''} ${isProcessing ? 'processing' : ''}`}
    onDragOver={(e) => {
      e.preventDefault();
      setIsDragging(true);
    }}
    onDragLeave={(e) => {
      e.preventDefault();
      setIsDragging(false);
    }}
    onDrop={onDrop}
  >
   
    {/* Rest of your existing upload area UI */}
    <input
      type="file"
      onChange={handleFileSelect}
      multiple
      style={{ display: 'none' }}
      id="file-input"
    />
    <input
      type="file"
      ref={folderInputRef}
      onChange={handleFolderSelect}
      webkitdirectory=""
      directory=""
      style={{ display: 'none' }}
      id="folder-input"
    />
</div>

<div
className={`border-2 border-dashed rounded-lg p-8 ${
  isDragging ? 'border-blue-500 bg-blue-50' : 'border-gray-300'
} text-center`}

onDragOver={!isUploading ? onDragOver : null}
onDragLeave={!isUploading ? onDragLeave : null}
onDrop={!isUploading ? onDrop : null}
>
<Upload className="mx-auto h-12 w-12 text-gray-400" />
<p className="mt-2 text-sm h-10 text-gray-600">
  Drag and drop files/folders here, or click buttons below to select
</p>

<div className="mt-4 flex justify-center gap-4">
  <div>
    <input
      type="file"
      multiple
      onChange={!isUploading || !isProcessing ? handleFileSelect : null}
      className="hidden"
      id="file-input"
      disabled={isUploading || isProcessing}
    />
    <button
        onClick={!isUploading || !isProcessing ? () => document.getElementById('file-input').click() : null}
        className={`px-4 py-2 bg-blue-500 text-white rounded flex items-center ${isUploading ? 'bg-gray-400 cursor-not-allowed' : 'hover:bg-blue-600'}`}
        disabled={isUploading || isProcessing}
      >
      <File className="w-4 h-4 mr-2" />
      Upload Files
    </button>
  </div>
  
  <div>
    <input
      type="file"
      webkitdirectory="true"
      directory="true"
      onChange={!isUploading || !isProcessing ? handleFolderSelect : null}
      className="hidden"
      id="folder-input"
      disabled={isUploading || isProcessing}
      ref={folderInputRef} // Reference to reset input
    />
    <button
        onClick={!isUploading || !isProcessing ? () => document.getElementById('folder-input').click() : null}
        className={`px-4 py-2 bg-green-500 text-white rounded flex items-center ${isUploading ? 'bg-gray-400 cursor-not-allowed' : 'hover:bg-green-600'}`}
        disabled={isUploading || isProcessing}
      >
      <Folder className="w-4 h-4 mr-2" />
      Upload Folder
    </button>
  </div>
</div>
</div>

<div className="mt-2 text-center text-gray-600 text-sm flex items-center">
<img src={HintIcon} alt="Hint Icon" className="w-5 h-5 mr-2" /> 
<p>To speed up file uploads, try uploading your data in <strong>.7z</strong> or <strong>.zip</strong> formats.</p>
</div>
{renderProcessingStatus()}
{fileStructure.size > 0 && (
<div className="mt-4">
  <h3 className="text-lg font-semibold mb-2">Selected Files:</h3>
  <div className="max-h-96 overflow-y-auto border rounded-lg p-4">
    {renderFileTree()}
  </div>
  
  {/* <div className="mt-4">
    <button
      onClick={uploadToS3}
      className="w-full px-4 py-2 bg-purple-500 text-white rounded hover:bg-purple-600 flex items-center justify-center"
    >
      <Upload className="w-4 h-4 mr-2" />
      Upload to S3
    </button>
  </div> */}

  {totalProgress > 0 && (
    <div className="mt-4">
      <div className="relative pt-1">
        <div className="flex mb-2 items-center justify-between">
          <div className="text-sm font-semibold text-green-900">
            Upload Progress
          </div>
          <div className="text-sm text-green-900">
            {Math.round(totalProgress)}%
          </div>
        </div>
        <div className="relative h-8 w-full bg-gray-200 rounded-full overflow-hidden">
          <div
            className="h-full bg-green-800 transition-all duration-300 relative"
            style={{ width: `${totalProgress}%` }}
          >
            <span className="absolute inset-0 flex items-center justify-center text-xs text-white font-semibold">
              {Math.round(totalProgress)}%
            </span>
          </div>
        </div>
      </div>
    </div>
  )}
</div>
)}

{uploadStatus && (
<div className="mt-4 p-4 border rounded bg-gray-50" style={{ userSelect: 'none' }}>
  <p>{uploadStatus}</p>
</div>
)}
</div>

                      <div style={{ paddingLeft: "10px" }}>
                        <div className="checkbox-wrapper-13">
                          <input
                            id="c1-13"
                            type="checkbox"
                            checked={policyCheck}
                            onChange={handleCheckboxChange}
                            disabled={isUploading}
                          />
                          <label htmlFor="c1-13" style={{ marginLeft: "15px", fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif" }}>
                            I agree to the{" "}
                            <a
                              href="https://www.surgeonslab.com/termsOfUse.php"
                              target="_blank"
                              rel="noopener"
                              style={{ color: "blue", textDecoration: "underline" }}
                            >
                              Terms of Use
                            </a>{" "}
                            and{" "}
                            <a
                              href="https://www.surgeonslab.com/privacyPolicy.php"
                              target="_blank"
                              rel="noopener"
                              style={{ color: "blue", textDecoration: "underline" }}
                            >
                              Privacy Policy
                            </a>
                            .
                          </label>
                        </div>
                      </div>
                      
              </div>

              {/* <div className="submit-container">
                  <div className={`submit ${submitDisabled ? 'disabled' : ''}`} onClick={uploadToS3} style={submitStyle} disabled={submitDisabled}>
                    <Upload className="w-4 h-4 mr-2" />Submit
                  </div>
              </div> */}
              <div className="mt-4 submit-container">
                <button
                  onClick={uploadToS3}
                  className="submit w-full px-4 py-2 text-white rounded flex items-center justify-center"
                  disabled={isUploading || isProcessing}
                >
                  <Upload className="w-4 h-4 mr-2" />
                  {/* {isProcessing ? 'Processing, Please wait' : isUploading ? 'Uploading...' : 'Submit'} */}
                  {isUploading ? 'Uploading...' : 'Submit'}
                </button>
              </div>


              {progressBarVisible && (
              <div class="progress-bar__container">
                  <div class="progress-bar">
                  <span class="progress-bar__text">Uploaded Successfully!</span>
                  </div>
              </div> 
              )}
              {progressBarVisible && (
              <p><i>Please do not refresh the page or press back while upload is in progress</i></p>
              )}
              
          </div>
      </div>
  );
};

export default UploadMaster;