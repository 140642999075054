import React, { useRef, useState } from 'react';
import './FileUpload.css';

const FileUpload = ({ onFilesUpload, disableInput }) => {
  const fileInputRef = useRef(null);
  const [folderName, setFolderName] = useState(null);
  const [fileCount, setFileCount] = useState(0);
  const [uploadButtonDisabled, setUploadButtonDisabled] = useState(false);
  const [uploadDisabled, setUploadDisabled] = useState(false);

  const handleFileChange = () => {
    const files = fileInputRef.current.files;
    const newFolderName = getFolderName(files); // Extract the folder name from the uploaded files
    console.log('Folder Name:', newFolderName);

    setFileCount(files.length);
    if (typeof onFilesUpload === 'function') {
      onFilesUpload(files, newFolderName, files.length); // Pass files and folder name to the parent component
    }

    // Update the folderName state with the new folder name
    setFolderName(newFolderName);

    // Update the fileCount state with the number of files in the selected folder
    // setFileCount(files.length);
  };

  const getFolderName = (files) => {
    if (!files || files.length === 0) {
      return null;
    }

    // Get the first file's webkitRelativePath
    const firstFile = files[0];
    const webkitRelativePath = firstFile.webkitRelativePath;

    // Extract the folder name from the webkitRelativePath
    const folderPathParts = webkitRelativePath.split('/');
    if (folderPathParts.length > 1) {
      // The folder name is the first segment of the path
      return folderPathParts[0];
    }

    return null;
  };

  return (
    <div >
      <form className="form">
        <div className={`file-upload-wrapper ${disableInput ? 'disabled' : ''}`} data-text={folderName ? folderName : "Select your files!"}>
          <input
            ref={fileInputRef}
            type="file"
            className={`file-upload-field ${disableInput ? 'disabled' : ''}`}
            multiple
            directory=""
            webkitdirectory=""
            onChange={handleFileChange}
          />
        </div>
      </form>
      {fileCount > 0 && (
        <p>Total number of files in the selected folder: {fileCount}</p>
      )}
    </div>
  );
};

export default FileUpload;