import UploadMaster from './Components/UploadMaster/UploadMaster';
import UploadMain from './Components/UploadMain/UploadMain';
import './App.css';
import React from 'react';
import {Route, Routes, HashRouter as Router, BrowserRouter} from 'react-router-dom';
// import ResetPassword from './Components/ResetPassword/ResetPassword';

  const App = () => {
    return (
      <div>
      <BrowserRouter>
          <Routes>
            <Route path="/" element={<UploadMaster />} />
            {/* <Route path="/ReportForm" element={<ReportForm />} />
            <Route path="/ResetPassword" element={<ResetPassword />} /> */}
          </Routes>
      </BrowserRouter>
      </div>
    );
  };
export default App;